$(document).ready(function() {
    $('.col-right a.read-more').click(function(e) {
        e.preventDefault();

        var ele = $(this);
        var target = $(this).prev('.more');

        if(ele.attr('data_open') == "true") {
            ele.removeAttr('data_open');
            ele.text('Read more...');
            target.fadeOut('20');
        }
        else {
            ele.attr('data_open', true);
            ele.text('Close');
            target.fadeIn('100');
        }
    });

    $('.col-left a').click(function(e) {
        if(typeof ga != "undefined") {
            var filename = $(this).text();
            ga('send','event','Homepage','File Download', filename);
        }
    });
});